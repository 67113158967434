import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {StartComponent} from './widgets/start/start.component';
import {RegistrationComponent} from './registration/registration.component';
import {MyAccountComponent} from './myaccount/myaccount.component';

const certificateRoutes: Routes = [
    { path: 'certificate', loadChildren: () => import('./widgets/certificate/certificate.module').then(m => m.CertificateModule)}
];

const adminRoutes: Routes = [
    { path: 'admin', loadChildren: () => import('./widgets/admin/admin.module').then(m => m.AdminModule)}
];

const projectRoutes: Routes = [
    { path: 'project', loadChildren: () => import('./widgets/project/project.module').then(m => m.ProjectModule)}
];

const stafflistRoutes: Routes = [
    { path: 'stafflist', loadChildren: () => import('./widgets/stafflist/stafflist.module').then(m => m.StafflistModule)}
];

const camerasRoutes: Routes = [
  {
    path: "webcams",
    loadChildren: () =>
      import("./widgets/webcams/webcams.module").then(
        (m) => m.WebcamesModule
      ),
  }
];

const routes: Routes = [
    { path: '',   redirectTo: '/start', pathMatch: 'full'},
    { path: 'start', component: StartComponent },
    { path: 'register', component: RegistrationComponent },
    { path: 'myaccount', component: MyAccountComponent },
    ...adminRoutes,
    ...certificateRoutes,
    ...projectRoutes,
    ...stafflistRoutes,
    ...camerasRoutes,
    { path: '**', redirectTo: '/start' }
];

@NgModule({
    imports: [ RouterModule.forRoot(routes, { enableTracing: false, relativeLinkResolution: 'legacy' }) ],
    exports: [ RouterModule ]
})
export class AppRoutingModule {}
