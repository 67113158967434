<div class="background-img">
  <lo-navbar></lo-navbar>
  <ng-container *ngIf="session$ | async as session">
    <div
      fxLayout="row wrap"
      fxLayoutAlign="space-around center"
      class="flexContainer"
    >
      <mat-card class="card" routerLink="/certificate" fxFlex="0 0 310px">
        <div class="card-header">
          <span class="card-text">
            {{ "certificates.pageTitle" | translate }}
          </span>
        </div>
        <div class="card-body">
          <img
            class="card-icon"
            src="/assets/Lambertsson_online_certificate_logo.png"
          />
        </div>
        <div class="card-footer">
          <span class="card-text">
            {{ "certificates.subTile" | translate }}
          </span>
        </div>
      </mat-card>
      <mat-card
        class="card"
        routerLink="/admin"
        *ngIf="session.authenticated && session.role == 'admin'"
        fxFlex="0 0 310px"
      >
        <div class="card-header">
          <span class="card-text"> {{ "admin.pageTitle" | translate }} </span>
        </div>
        <div class="card-body">
          <img
            class="card-icon"
            src="/assets/Lambertsson_online_admin_logo.png"
          />
        </div>
        <div class="card-footer">
          <span class="card-text"> {{ "admin.subTile" | translate }} </span>
        </div>
      </mat-card>
      <mat-card
        class="card"
        routerLink="/project"
        *ngIf="session.authenticated"
        fxFlex="0 0 310px"
      >
        <!-- <div class="cardTop"><fa-icon class="card-top-icon" [icon]="faBuilding"></fa-icon></div> -->
        <div class="card-header">
          <span class="card-text">
            {{ "projects.pageTitle" | translate }}
          </span>
        </div>
        <div class="card-body">
          <img
            class="card-icon"
            src="/assets/Lambertsson_online_tasks_logo.png"
          />
        </div>
        <div class="card-footer">
          <span class="card-text">
            {{ "projects.subTile" | translate }}
          </span>
        </div>
      </mat-card>
      <mat-card class="card" routerLink="/stafflist" fxFlex="0 0 310px">
        <div class="card-header">
          <span class="card-text">
            {{ "stafflist.pageTitle" | translate }}
          </span>
        </div>
        <div class="card-body">
          <img
            class="card-icon"
            src="/assets/Lambertsson_online_idcard_logo.png"
          />
        </div>
        <div class="card-footer">
          <span class="card-text">
            {{ "stafflist.subTile" | translate }}
          </span>
        </div>
      </mat-card>

      <mat-card class="card" routerLink="/webcams" fxFlex="0 0 310px">
        <div class="card-header">
          <span class="card-text">
            {{ "cameras.pageTitle" | translate }}
          </span>
        </div>
        <div class="card-body">
          <img
            class="card-icon"
            src="/assets/Lambertsson_online_cctv_logo.png"
          />
        </div>
        <div class="card-footer">
          <span class="card-text">
            {{ "cameras.subTile" | translate }}
          </span>
        </div>
      </mat-card>
    </div>
  </ng-container>

  <footer>
    <!-- <mat-toolbar> -->
    <a href="https://lambertsson.com" class="logo-link" target="_blank">
      <img
        class="logotype"
        alt="Lambertsson online logotype"
        src="/assets/logo.png"
      />
    </a>
    <span class="fillRemainingSpace"></span>
    <div class="footer-links">
      <a
        mat-button
        href="https://lambertsson.com/om-lambertsson/for-kunder/"
        color="primary"
        target="_blank"
        >{{ "common.FAQ" | translate }}</a
      >
      <a
        mat-button
        href="https://lambertsson.com/om-lambertsson/hantering-av-personuppgifter/"
        color="primary"
        target="_blank"
        >{{ "common.termsOfUse" | translate }}
      </a>
      <a
        mat-button
        href="https://lambertsson.com/dokument/mobile-privacy-policy/"
        color="primary"
        target="_blank"
        >{{ "common.privacyPolicy" | translate }}</a
      >
    </div>
    <!-- </mat-toolbar> -->
  </footer>
</div>
