import { Component, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";

@Component({ selector: "app-subsciption-component", template: "" })
export class SubsciptionComponent implements OnDestroy {
  subscriptions: Subscription[] = [];

  keyedSubscriptions: Subscription;

  ngOnDestroy() {
    this.unsubscribe();
  }

  subscribe(subscription: Subscription, key?: string) {
    if (key) {
      this.unsubscribe(key);
      this.subscriptions[key] = subscription;
    } else {
      this.subscriptions.push(subscription);
    }
  }

  unsubscribe(key?: string) {
    if (key) {
      if (this.subscriptions[key]) {
        this.subscriptions[key].unsubscribe();
      }
    } else {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }
}
