import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { App } from "./app";
import { Title } from "@angular/platform-browser";

@Injectable({ providedIn: "root" })
export class AppService {
  private appSource = new BehaviorSubject<App>({
    title: "",
    showProjectSideDrawer: false,
    showProductLink: false,
    showAdminSideDrawer: false,
    showHeader: true,
    project_id: null,
    project_name: null,
    widgetName: "",
    productCount: 0,
    returnCount: 0,
    showReturnMenu: false,
  });

  constructor(private _titleService: Title) {
    this._titleService.setTitle("Lambertsson Online");
  }

  appSelected$ = this.appSource.asObservable();

  selectApp(app: App) {
    if (app.title) {
      this._titleService.setTitle(`Lambertsson Online - ${app.title}`);
    }
    else this._titleService.setTitle('Lambertsson Online');
    this.appSource.next(app);
  }
}
