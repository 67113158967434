import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { RegistrationService } from "./registration.service";
import { CaptchaComponent } from "angular-captcha";
import { FormBuilder, Validators, FormControl } from "@angular/forms";
import { Registration } from "./registration";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { UniqueUserValidator } from "./uniqueUserValidator";
import { environment } from "src/environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { Company } from "../widgets/admin/companies/company";
import { debounceTime } from "rxjs/operators";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";

@Component({
  selector: "your-form-with-captcha",
  templateUrl: "registration.component.html",
  styleUrls: ["registration.component.css"],
  providers: [RegistrationService],
})
export class RegistrationComponent implements OnInit {
  public enebelCaptcha = environment.production && !environment.staging;

  @ViewChild(CaptchaComponent, { static: true })
  captchaComponent: CaptchaComponent;
  @ViewChild("phone", { static: true }) phone: ElementRef;
  newUserForm = this.fb.group({
    firstName: ["", Validators.required],
    lastName: ["", Validators.required],
    phone: [
      "+46",
      [
        Validators.pattern("^([+]46)\\s*(7[02369])\\s*(\\d{4})\\s*(\\d{3})$"),
        Validators.required,
        Validators.minLength(12),
        Validators.maxLength(12),
      ],
      this.uniqueUserValidator.validate.bind(this.uniqueUserValidator),
    ],
    company_id: ["", Validators.required],
    email: ["", [Validators.required, Validators.email]],
    captcha: ["", this.enebelCaptcha ? Validators.required : null],
    agreed: [false, Validators.requiredTrue],
    new_company_name: [""],
    new_company_vatnum: [""],
  });
  showNewCompany = false;
  registration: Registration;
  searchCompanyTerm: FormControl = new FormControl();
  searchCompanyResult: Array<Company>;
  toggleNewCompany = () => {
    this.showNewCompany = !this.showNewCompany;
    if(this.showNewCompany){
      this.newUserForm.get('company_id').disable();
      this.newUserForm.get('new_company_name').enable();
      this.newUserForm.get('new_company_vatnum').enable();
    }
    else{
      this.newUserForm.get('company_id').enable();
      this.newUserForm.get('new_company_name').disable();
      this.newUserForm.get('new_company_vatnum').disable();
    }
  }
  constructor(
    private registrationService: RegistrationService,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private router: Router,
    private uniqueUserValidator: UniqueUserValidator,
    public translate: TranslateService
  ) {
    this.searchCompanyTerm.valueChanges.pipe(debounceTime(500)).subscribe((search) => {
      this.registrationService.searchCompany(search).subscribe((response) => {
        this.searchCompanyResult = response;
      });
    });
  }

  ngOnInit(): void {}

  onSubmit(): void {
    if (this.newUserForm.invalid) {
      return;
    }

    // post the captcha data to the /your-app-backend-path on your backend
    if (this.enebelCaptcha) {
      // get the user-entered captcha code value to be validated at the backend side
      let userEnteredCaptchaCode = this.captchaComponent.userEnteredCaptchaCode;

      // get the id of a captcha instance that the user tried to solve
      let captchaId = this.captchaComponent.captchaId;

      const postData = {
        userEnteredCaptchaCode: userEnteredCaptchaCode,
        captchaId: captchaId,
      };

      this.registrationService.send(postData).subscribe(
        (response) => {
          if (response.success == false) {
            // captcha validation failed; reload image
            this.captchaComponent.reloadImage();
            // TODO: maybe display an error message, too
            this.newUserForm.controls["captcha"].setErrors({ incorrect: true });
          } else {
            this.register();
          }
        },
        (error) => {
          throw new Error(error);
        }
      );
    } else {
      this.register();
    }
  }

  register() {
    this.registration = new Registration(
      this.newUserForm.get("firstName").value.trim(),
      this.newUserForm.get("lastName").value.trim(),
      this.newUserForm.get("email").value.trim(),
      this.newUserForm.get("phone").value.trim().replace("+", ""),
      "user",
      this.showNewCompany? -1 : this.newUserForm.get("company_id").value,
      this.showNewCompany? this.newUserForm.get("new_company_name").value : null,
      this.showNewCompany? this.newUserForm.get("new_company_vatnum").value : null
    );

    const optionsPromises = [
      this.translate.get("registration.successMessage").toPromise(),
      this.translate.get("registration.errorMessage").toPromise(),
    ];

    Promise.all(optionsPromises).then((translations) => {
      this.registrationService.addUser(this.registration).subscribe(
        (registration) => {
          this.openSnackBar(translations[0], true);
        },
        (error) => {
          this.openSnackBar(translations[1], false);
        }
      );
    });
  }

  onPhoneChange(args) {
    setTimeout(() => {
      if (this.phone.nativeElement.value.length == 1) {
        this.phone.nativeElement.value = "+";
      } else if (this.phone.nativeElement.value.length == 2) {
        this.phone.nativeElement.value = "+4";
      } else if (this.phone.nativeElement.value.length == 3) {
        this.phone.nativeElement.value = "+46";
      } else if (this.phone.nativeElement.value.length > 12) {
        this.phone.nativeElement.value = this.phone.nativeElement.value.slice(
          0,
          -1
        );
      } else if (this.phone.nativeElement.value.length != 0) {
        this.phone.nativeElement.value =
          "+46" + this.phone.nativeElement.value.substring(3);
      }
    }, 250);
  }

  openSnackBar(message: string, redirect: boolean) {
    let snackBarRef = this._snackBar.open(message, "", {
      duration: 3000,
    });

    if (redirect) {
      snackBarRef.afterDismissed().subscribe(() => {
        this.goToStart();
      });
    }
  }

  goToStart() {
    this.router.navigate(["/start"]);
  }

  optionCompanySelected(selected: MatAutocompleteSelectedEvent) {
    this.newUserForm.get("company_id").setValue(selected.option.value.id);
    this.searchCompanyTerm.setValue(selected.option.value.name);
  }
}
