<mat-form-field appearance="fill">
  <mat-label> {{ "common.langSelect.label" | translate }} </mat-label>

  <mat-select
    [ngModel]="selectedLang | async"
    (selectionChange)="changeLang($event)"
  >
    <mat-option *ngFor="let lang of langOptions" [value]="lang">
      <span class="lang-select-value">
        <img class="lang-select-flag" [src]="lang.flag" />
        {{ lang.name }}</span
      >
    </mat-option>
  </mat-select>
</mat-form-field>
