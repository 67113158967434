import { Component, OnInit } from "@angular/core";
import { faUser, faUserHardHat } from "@fortawesome/pro-duotone-svg-icons";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { Session } from 'src/app/auth/session';
import { App } from 'src/app/app';
import { AppService } from 'src/app/app.service';
import { AuthService } from 'src/app/auth/auth.service';
import { LoginComponent } from 'src/app/login/login.component';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "lo-navbar",
  templateUrl: "./lo-navbar.component.html",
  styleUrls: ["./lo-navbar.component.css"],
})
export class LoNavbarComponent implements OnInit {
  faUser = faUser;
  faUserHardHat = faUserHardHat;
  session$: Observable<Session>;
  selectedApp$: Observable<App>;
  constructor(
    private _router: Router,
    private _appService: AppService,
    private _authService: AuthService,
    public _dialog: MatDialog,
    public translate: TranslateService
  ) {
    this.session$ = this._authService.session$;
    this.selectedApp$ = this._appService.appSelected$;
  }

  ngOnInit(): void {}

  logout() {
    this._authService.logout();
    this._router.navigate(["/start"]);
  }

  openLoginDialog(): void {
    this._dialog.open(LoginComponent, {
      width: "400px",
      panelClass: "lo-dialog",
      autoFocus: false,
    });
  }
}
