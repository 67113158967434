import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";

import { faTimes } from "@fortawesome/pro-regular-svg-icons";

import { MyAccountService } from "../myaccount.service";
import {
  FormControl,
  Validators,
  FormGroup,
  FormGroupDirective,
  NgForm,
} from "@angular/forms";
import { MyAccount } from "../myaccount";
import { passwordValidator } from "../password-validator.directive";
import { PasswordDialogData } from "../myaccount.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ErrorStateMatcher } from "@angular/material/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-dialog",
  templateUrl: "./dialog.component.html",
  styleUrls: ["dialog.component.css"],
  providers: [MyAccountService],
})
export class ChangePasswordDialogComponent implements OnInit {
  faTimes = faTimes;
  userForm: FormGroup = new FormGroup(
    {
      oldPassword: new FormControl("", [Validators.required]),
      newPasswordFirst: new FormControl("", [Validators.required]),
      newPasswordSecond: new FormControl("", [Validators.required]),
    },
    { validators: passwordValidator }
  );

  matcher = new MyErrorStateMatcher();

  constructor(
    public dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PasswordDialogData,
    private myAccountService: MyAccountService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public translate: TranslateService
  ) {}

  myAccount: MyAccount;

  ngOnInit() {}

  openSnackBar(message: string): any {
    let snackBarRef = this._snackBar.open(message, "", {
      duration: 3000,
    });

    return snackBarRef;
  }

  onSubmit(): void {
    if (this.userForm.invalid) {
      return;
    }

    this.myAccount = new MyAccount(
      this.userForm.get("oldPassword").value,
      this.userForm.get("newPasswordFirst").value
    );

    this.myAccountService.changePassword(this.myAccount).subscribe(
      (response) => {
        this.dialogRef.close();

        this.openSnackBar(response.response);
      },
      (error) => {
        // this.dialogRef.close();
        this.translate
          .get("me.new-password.errorMessage")
          .toPromise()
          .then((translations) => {
            this.openSnackBar(translations);
          });
      }
    );
  }

  cancle(): void {
    this.dialogRef.close();
  }
}

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    return control.touched &&
      control.parent.errors &&
      control.parent.errors.noPasswordMatch
      ? true
      : false;
  }
}
