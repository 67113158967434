import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  Inject,
} from "@angular/core";
import { MyAccountService } from "./myaccount.service";
import { CaptchaComponent } from "angular-captcha";
import {
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  Validators,
} from "@angular/forms";
import { MyAccount } from "./myaccount";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { AuthService } from "../auth/auth.service";
import { ErrorStateMatcher } from "@angular/material/core";
import { ChangePasswordDialogComponent } from "./dialog/dialog.component";
import { IconDefinition, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "your-form-with-captcha",
  templateUrl: "myaccount.component.html",
  styleUrls: ["myaccount.component.css"],
  providers: [MyAccountService],
})
export class MyAccountComponent implements OnInit {
  @ViewChild(CaptchaComponent, { static: true })
  captchaComponent: CaptchaComponent;
  @ViewChild("phone", { static: true }) phone: ElementRef;

  userForm = new FormGroup({
    firstName: new FormControl({ value: "", disabled: true }, [
      Validators.required,
    ]),
    lastName: new FormControl({ value: "", disabled: true }, [
      Validators.required,
    ]),
    phone: new FormControl({ value: "", disabled: true }, [
      Validators.required,
    ]),
    company: new FormControl({ value: "", disabled: true }, [
      Validators.required,
    ]),
    email: new FormControl({ value: "", disabled: true }, [
      Validators.required,
      Validators.email,
    ]),
  });

  matcher = new MyErrorStateMatcher();

  myAccount: MyAccount;

  constructor(
    private myAccountService: MyAccountService,
    private _snackBar: MatSnackBar,
    private router: Router,
    public dialog: MatDialog,
    private authService: AuthService,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.myAccountService.getUser().subscribe((user) => {
      this.userForm.get("firstName").setValue(user.firstname);
      this.userForm.get("lastName").setValue(user.lastname);
      this.userForm.get("phone").setValue(user.phone);
      this.userForm.get("company").setValue(user.company?.name);
      this.userForm.get("email").setValue(user.email);

      this.myAccount = new MyAccount("", "");
    });
  }

  openSnackBar(message: string): any {
    let snackBarRef = this._snackBar.open(message, "", {
      duration: 3000,
    });

    return snackBarRef;
  }

  openChangePasswordDialog(): void {
    const dialogRef = this.dialog.open(ChangePasswordDialogComponent, {
      width: "400px",
      data: { model: this.myAccount, confirmed: false },
      panelClass: "lo-dialog",
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }

      // if (result.confirmed) {
      //     this._usersService.addUser(result.model).subscribe((response) => {
      //         if (response.id) {
      //             this._usersService.attachProjects(response.id, result.model.projects, result.model.permissions).subscribe();
      //         }

      //         this.loadUserPage();
      //     });
      // }
    });
  }

  removeMe() {
    this.openDialog();
  }

  goToUserPolicy() {
    window.open(
      "https://lambertsson.com/om-lambertsson/hantering-av-personuppgifter/"
    );
  }

  goToStart() {
    this.router.navigate(["/start"]);
  }

  openDialog(): void {
    const optionsPromises = [
      this.translate.get("me.deleteMessageSuccess").toPromise(),
      this.translate.get("me.deleteMessageError").toPromise(),
    ];

    const dialogRef = this.dialog.open(Confirm, {
      data: { name: "", email: "" },
      width: "400px",
      panelClass: "lo-dialog",
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      Promise.all(optionsPromises).then((translations) => {
        if (result === "delete") {
          this.myAccountService.removeMe().subscribe((result) => {
            if (result && result.success) {
              let snackBarRef = this.openSnackBar(translations[0]);

              snackBarRef.afterDismissed().subscribe(() => {
                this.authService.logout();
                this.goToStart();
              });
            } else {
              this.openSnackBar(translations[1]);
            }
          });
        }
      });
    });
  }
}

export interface DialogData {
  email: string;
  name: string;
}

export interface PasswordDialogData {
  model: MyAccount;
  confirmed: boolean;
}

@Component({
  selector: "confirm",
  templateUrl: "confirm.html",
})
export class Confirm {
  faTimes: IconDefinition = faTimes;

  constructor(
    public dialogRef: MatDialogRef<Confirm>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  delete(): void {
    this.dialogRef.close("delete");
  }
}

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    return control.touched &&
      control.parent.errors &&
      control.parent.errors.noPasswordMatch
      ? true
      : false;
  }
}
