import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { MyAccount } from "./myaccount";

@Injectable()
export class MyAccountService {
  constructor(private http: HttpClient) {}

  getUser(): Observable<any> {
    return this.http.get("me");
  }

  changePassword(myAccount: MyAccount): Observable<any> {
    return this.http.post<MyAccount>("changepassword", myAccount).pipe(
      map((res) => {
        if (res.success !== "true" || !res.success) {
          throw res.response;
        }
        return res;
      }),
      catchError((err) => {
        throw err;
      })
    );
  }

  removeMe(): Observable<any> {
    return this.http
      .delete("me")
      .pipe(catchError(this.handleError<any>("deleteme")));
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      //this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
