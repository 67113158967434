<div mat-dialog-title></div>

<div mat-dialog-content>
  <img src="/assets/logotype.png" class="imgCenter" />
  <form [formGroup]="credentialsForm" (ngSubmit)="submit()">
    <mat-form-field>
      <span matPrefix>+46 &nbsp;</span>
      <input
        id="username"
        formControlName="username"
        required
        [placeholder]="'common.mobileNumber' | translate"
        matInput
        type="tel"
      />
      <mat-error *ngIf="username?.errors && !username?.errors?.phoneNotFound">
        {{ "login.errorFormat" | translate }}
      </mat-error>
      <mat-error *ngIf="username?.errors?.phoneNotFound">
        {{ "login.errorNofound" | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field *ngIf="!newPasswordMode">
      <input
        id="password"
        formControlName="password"
        required
        [placeholder]="'common.password' | translate"
        type="password"
        matInput
      />
      <mat-error *ngIf="password.errors?.wrongPassword">
        {{ "login.errorWrongPassword" | translate }}
      </mat-error>
    </mat-form-field>

    <lo-lang-select></lo-lang-select>

    <div>
      <mat-dialog-actions>
        <button mat-raised-button color="primary" type="submit">
          <span *ngIf="newPasswordMode">{{
            "login.createNewCode" | translate
          }}</span>
          <span *ngIf="!newPasswordMode">{{ "common.login" | translate }}</span>
        </button>
      </mat-dialog-actions>
      <mat-dialog-actions>
        <button
          mat-raised-button
          color="primary"
          (click)="toggleRequestNewPasswordMode($event)"
        >
          <span *ngIf="newPasswordMode">{{
            "login.backToLogin" | translate
          }}</span>
          <span *ngIf="!newPasswordMode">{{
            "login.forgotPassword" | translate
          }}</span>
        </button>
      </mat-dialog-actions>
    </div>
  </form>

  <div>
    <span>{{ "login.noAccount" | translate }} </span>
    <a (click)="dialogRef.close()" routerLink="/register">{{
      "login.applyForAnAccount" | translate
    }}</a>
  </div>
</div>
