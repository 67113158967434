<div mat-dialog-title>
  <h2>{{ "me.deleteTitle" | translate }}</h2>

  <button mat-icon-button aria-label="Close open dialog" [mat-dialog-close]>
    <fa-icon [icon]="faTimes" size="2x"></fa-icon>
  </button>
</div>
<mat-divider></mat-divider>

<div mat-dialog-content>
  <p>{{ "me.deleteMessageConfirm" | translate }}</p>
  <div mat-dialog-actions>
    <button
      mat-raised-button
      (click)="delete()"
      cdkFocusInitial
      color="primary"
    >
      {{ "common.yes" | translate }}
    </button>
    <button mat-raised-button [mat-dialog-close]>
      {{ "common.cancel" | translate }}
    </button>
  </div>
</div>
