import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { Lang } from "./lang";
import { TranslateService } from "@ngx-translate/core";

// supported langs array
const langData = [
  {
    value: "sv",
    name: "Svenska",
    flag: "/assets/flags/Flag_of_Sweden.svg",
    toString: () => {
      return "Svenska";
    },
  },
  {
    value: "en",
    name: "English",
    flag: "/assets/flags/Flag_of_the_United_Kingdom.svg",
    toString: () => {
      return "English";
    },
  },
];

@Injectable({ providedIn: "root" })
export class LangService {
  public langOptions: Lang[];

  public selectedLang$: BehaviorSubject<Lang> = new BehaviorSubject<Lang>(null);

  constructor(private _translate: TranslateService) {
    this.langOptions = langData.sort((a, b) =>
      a.name < b.name ? -1 : a.name > b.name ? 1 : 0
    );

    this.loadLang();
  }

  // Loads stored lang
  loadLang() {
    this._translate.setDefaultLang("sv");
    this.setLang(this.getSelectedLang());
  }

  getSelectedLang = (): Lang =>
    localStorage.getItem("selected_lang")
      ? this.langOptions.find(
          (lang) => lang.value === localStorage.getItem("selected_lang")
        )
      : this.getDeviceLang();

  // Sets and stores lang
  setLang(selectedLang: Lang): void {
    localStorage.setItem("selected_lang", selectedLang.value);
    const selected = localStorage.getItem("selected_lang");

    // Let _translate.use finish before selecting language 
    this._translate.use(selected)
      .subscribe(() => this.selectedLang$.next(this.getSelectedLang()));
    // this.selectedLang$.next(this.getSelectedLang());
  }

  // Gets devices lang but if lang is not supported en is set
  getDeviceLang = (): Lang => {
    const lang: Lang = this.langOptions.find(
      (lang: Lang) => lang.value == navigator.language.substring(0, 2)
    );

    if (lang) {
      return this.langOptions.find((lang) => lang.value == "en");
    } else {
      return lang;
    }
  };
}
