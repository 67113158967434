<div>
  <button
    mat-icon-button
    [disableRipple]="true"
    class="user-menu-button"
    [matMenuTriggerFor]="menu"
    aria-label="Logout button with a menu"
  >
    <span>
      {{ (selectedLang | async).value }}
    </span>
    <fa-icon [icon]="faCaretDown"></fa-icon>
  </button>
  <mat-menu
    #menu="matMenu"
    panelClass="mat-elevation-z2"
    [overlapTrigger]="false"
    class="user-menu"
  >
    <button
      *ngFor="let lang of langOptions"
      mat-menu-item
      (click)="changeLang(lang)"
    >
      <span class="lang-select-value">
        <img class="lang-select-flag" [src]="lang.flag" />
        {{ lang.value }} - {{ lang.name }}</span
      >
    </button>
  </mat-menu>
</div>
