import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError, mapTo, tap } from "rxjs/operators";
import { BehaviorSubject, Observable, of, throwError } from "rxjs";
import { Router } from "@angular/router";
import { Session } from "./session";
import * as moment from "moment";

import { environment } from "src/environments/environment";

@Injectable()
export class AuthService {
  private session: BehaviorSubject<Session> = new BehaviorSubject<Session>({
    authenticated: localStorage.getItem("access_token") ? true : false,
    name: localStorage.getItem("name"),
    role: localStorage.getItem("role"),
    user_id: localStorage.getItem("user_id"),
  });

  constructor(private http: HttpClient, private router: Router) {}

  getAccessToken(username: string, password: string): Observable<any> {
    const url = `${environment.loApiUrl}/oauth/token`;

    //const url = 'https://api.lambertsson-online.se/oauth/token';
    //const url = 'https://lambertsson-online-api.stagehost.se/oauth/token';
    // const url = 'https://a61ce4d1.ngrok.io/oauth/token';
    const postData = `grant_type=password&client_id=2&client_secret=${environment.oAuthClientSecret}&username=46${username}&password=${password}`;

    return this.http
      .post<any>(url, postData, {
        headers: new HttpHeaders({
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        }),
      })
      .pipe(
        tap((authResult) => {
          if (authResult && authResult.access_token) {
            this.getUserInfo(authResult);
          }
        }),
        catchError((err) => {
          return of(false);
        })
      );
  }

  getUserInfo(authResult) {
    this.http
      .get<any>("user", {
        headers: new HttpHeaders().set(
          "Authorization",
          `Bearer ${authResult.access_token}`
        ),
      })
      .pipe(
        tap((profile) => {
          if (profile) {
            this._setSession(authResult, profile);
          }
        }),
        catchError(this.handleError<any>("getUserInfo", []))
      )
      .subscribe();
  }

  private _setSession(authResult, profile) {
    localStorage.setItem("access_token", authResult.access_token);
    localStorage.setItem("name", profile.name);
    localStorage.setItem("role", profile.role);
    localStorage.setItem("user_id", profile.user_id);

    const session = new Session();
    session.authenticated = true;
    session.name = profile.name;
    session.role = profile.role;
    session.user_id = profile.user_id;

    this.session.next(session);
  }

  logout() {
    localStorage.setItem("access_token", "");
    localStorage.setItem("name", "");
    localStorage.setItem("role", "");

    const session = new Session();
    session.authenticated = false;
    session.name = "";
    session.role = "";

    this.session.next(session);

    this.router.navigate(["/start"]);
  }

  get session$(): Observable<Session> {
    return this.session.asObservable();
  }

  validatePhone(phone: string): Observable<any> {
    return this.http.post<any>("validatephone", { phone: phone });
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      //this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
