import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoPanelCardComponent } from "./lo-panel-card/lo-panel-card.component";
import { LoPanelCardContentRowComponent } from "./lo-panel-card/lo-panel-card-row/lo-panel-card-row.component";
import { LOIconeCardComponent } from "./lo-icone-card/lo-icone-card.component";
import { LoPanelCardColumnComponent } from "./lo-panel-card/lo-panel-card-column/lo-panel-card-column.component";
import { LoPanelCardContentComponent } from "./lo-panel-card/lo-panel-card-content/lo-panel-card-content.component";
import { LoProductCardComponent } from "./lo-product-card/lo-product-card.component";
import { MatCardModule } from "@angular/material/card";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { FlexLayoutModule } from "@angular/flex-layout";
import { LoFloatingTotalDisplayComponent } from "./lo-floating-total-display/lo-floating-total-display.component";
import { LoNavbarComponent } from "./lo-navbar/lo-navbar.component";
import { MatMenuModule } from "@angular/material/menu";
import { MatButtonModule } from "@angular/material/button";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatListModule } from "@angular/material/list";
import { RouterModule } from "@angular/router";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatBadgeModule } from "@angular/material/badge";

import { LayoutModule } from "@angular/cdk/layout";
import { LoImgCardComponent } from "./lo-img-card/lo-img-card.component";
import { LoVideoCardComponent } from "./lo-video-card/lo-video-card.component";
import { LoPanelCardHeaderComponent } from "./lo-panel-card/lo-panel-card-header/lo-panel-card-header.component";
import { LoLangSelectComponent } from "./lang/lo-lang-select/lo-lang-select.component";
import { HttpClient } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { MatSelectModule } from "@angular/material/select";
import { LoLangSelectSmallComponent } from "./lang/lo-lang-select-small/lo-lang-select-small.component";
import { LoSidenavComponent } from "./lo-sidenav/lo-sidenav.component";

import { NgxChartsModule } from "@swimlane/ngx-charts";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { LoTimedataChartComponent } from "../widgets/project/climate-and-energy/lo-sensor-charts/lo-timedata-chart/lo-timedata-chart.component";
import { LoEventlogLineChartComponent } from "../widgets/project/climate-and-energy/lo-sensor-charts/lo-timedata-line-chart/lo-timedata-line-chart.component";
import { LoEventlogBarChartComponent } from "../widgets/project/climate-and-energy/lo-sensor-charts/lo-timedata-bar-chart/lo-timedata-bar-chart.component";
import { MatSidenavModule } from "@angular/material/sidenav";

@NgModule({
  declarations: [
    LoPanelCardComponent,
    LoPanelCardContentRowComponent,
    LOIconeCardComponent,
    LoPanelCardColumnComponent,
    LoProductCardComponent,
    LoPanelCardContentComponent,
    LoFloatingTotalDisplayComponent,
    LoNavbarComponent,
    LoImgCardComponent,
    LoVideoCardComponent,
    LoPanelCardHeaderComponent,
    LoLangSelectComponent,
    LoLangSelectSmallComponent,
    LoTimedataChartComponent,
    LoEventlogLineChartComponent,
    LoEventlogBarChartComponent,
    LoSidenavComponent,
  ],
  exports: [
    LoPanelCardComponent,
    LoPanelCardContentRowComponent,
    LoPanelCardColumnComponent,
    LOIconeCardComponent,
    LoProductCardComponent,
    LoPanelCardContentComponent,
    LoFloatingTotalDisplayComponent,
    LoNavbarComponent,
    LoImgCardComponent,
    LoVideoCardComponent,
    LoPanelCardHeaderComponent,
    LoLangSelectComponent,
    LoLangSelectSmallComponent,
    TranslateModule,
    LoTimedataChartComponent,
    LoEventlogLineChartComponent,
    LoEventlogBarChartComponent,
    LoSidenavComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    MatCardModule,
    FontAwesomeModule,
    FlexLayoutModule,
    MatMenuModule,
    MatButtonModule,
    MatToolbarModule,
    MatListModule,
    MatSelectModule,
    TranslateModule,
    MatGridListModule,
    MatIconModule,
    LayoutModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    NgxChartsModule,
    MatSidenavModule,
    MatBadgeModule,
  ],
})
export class LoComponentsModule {}
