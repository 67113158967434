import { Injectable, OnDestroy } from "@angular/core";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class MatPaginatorConfig extends MatPaginatorIntl implements OnDestroy {

    unsubscribe: Subject<void> = new Subject<void>();

    constructor(private _translate: TranslateService) {
        super();

        this._translate.stream(['common.itemsPerPage', 'common.prevPage', 'common.nextPage', ])
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(t => {
                this.itemsPerPageLabel = t['common.itemsPerPage'];
                this.previousPageLabel = t['common.prevPage'];
                this.nextPageLabel = t['common.nextPage'];
                this.changes.next();
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}