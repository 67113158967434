<ng-container *ngIf="selectedApp$ | async as app">
  <mat-toolbar
    [style.visibility]="app.showHeader == true ? 'visible' : 'hidden'"
    class="header"
  >
    <a routerLink="/start" class="logotypecontainer">
      <img
        src="/assets/Lambertsson_online_icon_logo.png"
        class="logotypeicon"
      />
      <img
        class="logotype"
        alt="Lambertsson online logotype"
        src="/assets/Lambertsson_online_logo.png"
      />
    </a>
    <span class="page-title">{{ app.widgetName }}</span>
    <span class="fillRemainingSpace"></span>

    <span class="lo-navbar-contact-support">
      <ul>
       <li><b>{{'common.needHelp' | translate}}</b></li> 
       <li><i class="material-icons">email</i> <a href="mailto:online.support@lambertsson.com">online.support@lambertsson.com</a></li>
       <li><i class="material-icons">phone</i> 020-88 25 00</li>
      </ul>
    </span>

    <lo-lang-select-small></lo-lang-select-small>

    <ng-container *ngIf="session$ | async as session">
      <button
        mat-icon-button
        [disableRipple]="true"
        class="user-menu-button"
        [matMenuTriggerFor]="menu"
        aria-label="Logout button with a menu"
        *ngIf="session.authenticated; else loggedOut"
      >
        <span class="user-menu-button-wrapper">
          <fa-icon [icon]="faUserHardHat" class="userIcon" size="lg"></fa-icon>
        </span>
      </button>
      <mat-menu
        #menu="matMenu"
        panelClass="mat-elevation-z2"
        [overlapTrigger]="false"
        class="user-menu"
      >
        <span mat-menu-item [disabled]="true" class="userName">
          <b>{{ session.name }}</b>
        </span>
        <mat-divider></mat-divider>
        <button mat-menu-item routerLink="/myaccount">
          {{ 'me.pageTitle' | translate }}
        </button>
        <button mat-menu-item (click)="logout()">
          {{ 'common.logout' | translate }}
        </button>
      </mat-menu>
    </ng-container>
    <ng-template #loggedOut>
      <span class="login-menu-wrapper">
        <!-- TODO: LO-108: can be removed?-->
        <!-- <button mat-button color="primary" routerLink="/register">
          Ny användare
        </button> -->
        <button
          mat-button
          color="primary"
          class="mat-bold"
          (click)="openLoginDialog()"
        >
          {{ 'common.login' | translate }}
        </button>
      </span>
    </ng-template>
  </mat-toolbar>
</ng-container>
