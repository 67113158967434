import { Component, OnInit } from "@angular/core";
import { MatSelectChange } from "@angular/material/select";
import { BehaviorSubject, Subject } from "rxjs";
import { Lang } from "../lang";
import { LangService } from "../lang.service";

@Component({
  selector: "lo-lang-select",
  templateUrl: "./lo-lang-select.component.html",
  styleUrls: ["./lo-lang-select.component.scss"],
})
export class LoLangSelectComponent {
  langOptions: Lang[];
  selectedLang: BehaviorSubject<Lang>;

  constructor(private _langService: LangService) {
    this._langService.loadLang();
    this.langOptions = this._langService.langOptions;
    this.selectedLang = this._langService.selectedLang$;
  }

  changeLang(e: MatSelectChange) {
    this._langService.setLang(e.value);
  }
}
