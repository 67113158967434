import { Component } from "@angular/core";
import { AppService } from "./app.service";
import { MatDialog } from "@angular/material/dialog";
import { LoginComponent } from "./login/login.component";
import { faUser, faUserHardHat } from "@fortawesome/pro-duotone-svg-icons";
import { Observable } from "rxjs";
import { Router, NavigationEnd } from "@angular/router";
import { AngularFireAnalytics } from "@angular/fire/analytics";
import { Session } from "./auth/session";
import { App } from "./app";
import { AuthService } from "./auth/auth.service";
import { environment } from "src/environments/environment";
import { LangService } from "./lo-components/lang/lang.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-root",
  styleUrls: ["./app.component.css"],
  templateUrl: "./app.component.html",
  providers: [AppService],
})
export class AppComponent {
  faUser = faUser;
  faUserHardHat = faUserHardHat;
  session$: Observable<Session>;
  selectedApp$: Observable<App>;

  public constructor(
    analytics: AngularFireAnalytics,
    private _router: Router,
    private _appService: AppService,
    private _authService: AuthService,
    public _dialog: MatDialog,
    public translate: TranslateService,
    public langService: LangService
  ) {
    this.session$ = this._authService.session$;
    this.selectedApp$ = this._appService.appSelected$;

    this._router.events.subscribe((y: NavigationEnd) => {
      analytics.logEvent(y.urlAfterRedirects);
    });
  }

  

  ngOnInit(): void {
    // Displays some testing data
    console.log(
      `%c Lambertsson %c Online \r
%c                       ${environment.appVersion} \r
${" staging: " + environment.staging}               \r
${" production: " + environment.production}            `,
      "background: #EE0F54; color: #143D7E; font-size: 1rem",
      "background: #143D7E; color: #EE0F54; font-size: 1rem",
      "background: #143D7E; color: #EE0F54"
    );
  }

  logout() {
    this._authService.logout();
    this._router.navigate(["/start"]);
  }

  openLoginDialog(): void {
    this._dialog.open(LoginComponent, {
      width: "400px",
      panelClass: "lo-dialog",
      autoFocus: false,
    });
  }
}
