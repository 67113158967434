import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {AuthService} from './auth.service';
import {Session} from './session';

@Injectable()
export class AdminAuthGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        return this.authService.session$.pipe(
            take(1),
            map((session: Session) => {
                if(!session.authenticated) {
                    this.router.navigate(['/start']);
                    return false;
                }
                else if(session.authenticated && session.role != 'admin'){
                    this.router.navigate(['/start']);
                    return false;
                }

                return true;
            })
        );
    }
}
