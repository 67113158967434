<lo-navbar></lo-navbar>

<div class="lo-form-page">
  <mat-card id="newUser">
    <form autocomplete="off" [formGroup]="userForm">
      <mat-card-content>
        <h1>{{ "me.pageTitle" | translate }}</h1>
        <mat-form-field>
          <input
            matInput
            [placeholder]="'common.firstName' | translate"
            type="text"
            formControlName="firstName"
            required
          />
          <mat-error *ngIf="userForm.get('firstName').errors">
            {{ "common.firstNameHintMissing" | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input
            matInput
            [placeholder]="'common.lastName' | translate"
            type="text"
            formControlName="lastName"
            required
          />
          <mat-error *ngIf="userForm.get('lastName').errors">
            {{ "common.lastNameHintMissing" | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input
            matInput
            [placeholder]="'common.mobileNumber' | translate"
            #phone
            formControlName="phone"
            required
          />
          <mat-error *ngIf="userForm.get('phone').errors">
            {{ "common.mobileNumberHintMissing" | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input
            matInput
            [placeholder]="'common.email' | translate"
            formControlName="email"
            required
          />
          <mat-error *ngIf="userForm.get('email').errors">
            {{ "common.emailHintMissing" | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input
            matInput
            [placeholder]="'common.company' | translate"
            formControlName="company"
            required
          />
          <mat-error *ngIf="userForm.get('company').errors">
            {{ "common.companyHintMissing" | translate }}
          </mat-error>
        </mat-form-field>
        <lo-lang-select></lo-lang-select>
      </mat-card-content>
    </form>

    <mat-card-actions>
      <button
        mat-raised-button
        color="primary"
        (click)="openChangePasswordDialog()"
      >
        {{ "me.new-password.pageTitle" | translate }}
      </button>
    </mat-card-actions>
  </mat-card>

  <mat-card>
    <mat-card-content fxLayout="row" fxLayoutAlign="space-around">
      <button mat-button (click)="goToUserPolicy()" class="action blue">
        {{ "me.handlingOfPersonalData" | translate }}
      </button>
      <button mat-button (click)="removeMe()" class="action red">
        {{ "me.deleteMyAccount" | translate }}
      </button>
    </mat-card-content>
  </mat-card>
</div>
