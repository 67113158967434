import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Registration} from './registration';
import { Company } from '../widgets/admin/companies/company';

@Injectable({ providedIn: 'root' })
export class RegistrationService {

    constructor(private http: HttpClient) { }

    send(data: Object): Observable<any> {
        const options = {
          headers: new HttpHeaders({'Content-Type': 'application/json; charset=utf-8'})
        };
        return this.http.post('captcha', data, options);
    }

    addUser(registration: Registration): Observable<Registration>{
        return this.http.post<Registration>('registrateuser', registration).pipe(
            catchError(err => {
                throw err;
            })
        );
    }

    isUserTaken(phone: string): Observable<any>{
        const param = phone.replace("+", "");
       return this.http.post<any>('validatephone', {"phone": param});
    }


    searchCompany(term ) {
        const httpOptions = {
            headers: new HttpHeaders({ 'Accept': 'application/json', 'Content-Type': 'application/x-www-form-urlencoded' }),
            params: new HttpParams()
                .set('search', term)
                .set('sort_col', 'name')
        };

        return this.http.get<Company[]>("companies", httpOptions).pipe(
            map(res =>  res['data']),
            catchError(this.handleError('search', []))
        );
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T> (operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            //this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }
}

