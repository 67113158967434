import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MatDialogRef } from "@angular/material/dialog";
import { AuthService } from "../auth/auth.service";
import { FormBuilder, Validators, AbstractControl } from "@angular/forms";
import { map } from "rxjs/operators";
import { LoginService } from "./login.service";

export class Credentials {
  username: string;
  password: string;
}

/**
 * @title Basic Inputs
 */
@Component({
  selector: "app-login",
  styleUrls: ["./login.component.css"],
  templateUrl: "./login.component.html",
})
export class LoginComponent implements OnInit {
  newPasswordMode = false;

  credentialsForm = this.fb.group({
    username: [
      "",
      [
        Validators.pattern("^s*(7[02369])\\s*(\\d{4})\\s*(\\d{3})$"),
        Validators.required,
        Validators.minLength(9),
        Validators.maxLength(9),
      ],
      this.validatePhone.bind(this),
    ],
    password: ["", Validators.required],
  });

  credentials: Credentials;

  constructor(
    private authService: AuthService,
    private router: Router,
    public dialogRef: MatDialogRef<LoginComponent>,
    private fb: FormBuilder,
    private loginService: LoginService
  ) {}

  ngOnInit() {
    this.credentials = new Credentials();
    this.credentials.username = "";
    this.credentials.password = "";
  }

  get username() {
    return this.credentialsForm.get("username");
  }
  get password() {
    return this.credentialsForm.get("password");
  }

  login(): void {
    if (this.credentialsForm.invalid) {
      return;
    }

    this.authService
      .getAccessToken(this.username.value, this.password.value)
      .subscribe((response) => {
        if (response && response.access_token) {
          this.password.setErrors({ wrongPassword: false });
          this.router.navigate(["/"]);
          this.dialogRef.close();
        } else {
          this.password.setErrors({ wrongPassword: true });
        }
      });
  }

  toggleRequestNewPasswordMode(event) {
    event.preventDefault()
    this.newPasswordMode = !this.newPasswordMode;
  }

  submit() {
    if (this.newPasswordMode) {
      if (this.username.value.length < 4) {
        return false;
      }

      this.loginService.getPassword(this.username.value).subscribe(
        (result) => {
          //   this.translate
          //     .get("login.smsHasBeenSent")
          //     .toPromise()
          //     .then((res) => {
          //       alert(res);
          //     });
        },
        (error) => {
          //   this.translate
          //     .get("common.errorSomethingWentWrong")
          //     .toPromise()
          //     .then((res) => {
          //       alert(res);
          //     });
        }
      );
    } else {
      if (this.username.value.length < 4 || !this.password.value) {
        return false;
      }

      this.login();
    }
  }

  validatePhone(control: AbstractControl) {
    return this.authService.validatePhone("46" + control.value).pipe(
      map((res) => {
        return res.success == "false" ? null : { phoneNotFound: true };
      })
    );
  }
}
