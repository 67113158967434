import { Component } from "@angular/core";
import { faCaretDown, IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { BehaviorSubject } from "rxjs";
import { Lang } from "../lang";
import { LangService } from "../lang.service";

@Component({
  selector: "lo-lang-select-small",
  templateUrl: "./lo-lang-select-small.component.html",
  styleUrls: ["./lo-lang-select-small.component.scss"],
})
export class LoLangSelectSmallComponent {
  faCaretDown: IconDefinition = faCaretDown;
  langOptions: Lang[];
  selectedLang: BehaviorSubject<Lang>;

  constructor(private _langService: LangService) {
    this._langService.loadLang();
    this.langOptions = this._langService.langOptions;
    this.selectedLang = this._langService.selectedLang$;
  }

  changeLang(e: Lang) {
    this._langService.setLang(e);
  }
}
