<div mat-dialog-title>
  <h2>{{ "me.new-password.pageTitle" | translate }}</h2>

  <button mat-icon-button aria-label="Close open dialog" (click)="cancle()">
    <fa-icon [icon]="faTimes" size="2x"></fa-icon>
  </button>
</div>

<mat-divider></mat-divider>

<div mat-dialog-content>
  <form autocomplete="off" [formGroup]="userForm" (ngSubmit)="onSubmit()">
    <mat-form-field>
      <input
        matInput
        [placeholder]="'me.new-password.currentPassword' | translate"
        formControlName="oldPassword"
        required
        type="password"
      />
      <mat-error *ngIf="userForm.get('oldPassword').errors">{{
        "me.new-password.currentPasswordHintMissing" | translate
      }}</mat-error>
    </mat-form-field>
    <mat-form-field>
      <input
        matInput
        [placeholder]="'me.new-password.newPassword' | translate"
        formControlName="newPasswordFirst"
        required
        type="password"
      />
      <mat-error *ngIf="userForm.get('newPasswordFirst').errors">{{
        "me.new-password.newPasswordHintMissing" | translate
      }}</mat-error>
    </mat-form-field>
    <mat-form-field>
      <input
        matInput
        [placeholder]="'me.new-password.confirmPassword' | translate"
        formControlName="newPasswordSecond"
        required
        type="password"
        [errorStateMatcher]="matcher"
      />
      <mat-error>{{
        "me.new-password.confirmPasswordErrorNoMatch" | translate
      }}</mat-error>
    </mat-form-field>

    <mat-dialog-actions>
      <button
        fxFlex="125px"
        [disabled]="!userForm.valid"
        mat-raised-button
        color="primary"
        type="submit"
      >
        {{ "common.save" | translate }}
      </button>
    </mat-dialog-actions>
  </form>
</div>
