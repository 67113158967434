import { AfterViewInit, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  faFileCertificate,
  faBuilding,
  faCogs,
  faUsersCog,
} from "@fortawesome/pro-duotone-svg-icons";
import { AuthService } from "../../auth/auth.service";
import { App } from "../../app";
import { AppService } from "../../app.service";
import { Observable } from "rxjs";
import { Session } from "../../auth/session";
import { take } from "rxjs/operators";
import { SubsciptionComponent } from "src/utils/SubsciptionComponent";

@Component({
  selector: "app-start",
  styleUrls: ["./start.component.scss"],
  templateUrl: "./start.component.html",
})
export class StartComponent
  extends SubsciptionComponent
  implements OnInit, AfterViewInit
{
  faCogs = faCogs;
  faFileCertificate = faFileCertificate;
  faBuilding = faBuilding;
  faUsersCog = faUsersCog;
  session$: Observable<Session>;
  selectedApp$: Observable<App>;
  app: App;

  constructor(
    private router: Router,
    public authService: AuthService,
    public appService: AppService
  ) {
    super();
    this.subscribe(
      this.appService.appSelected$.pipe(take(1)).subscribe((app) => {
        this.app = app;

        this.app.showProjectSideDrawer = false;
        this.app.showProductLink = false;
        this.app.showAdminSideDrawer = false;
        this.app.showHeader = true;
        this.app.project_id = null;
        this.app.project_name = null;
        this.app.widgetName = "";
        this.app.title = "";
        this.app.showReturnMenu = false;

        this.appService.selectApp(this.app);
      })
    );

    this.session$ = this.authService.session$;
    this.selectedApp$ = this.appService.appSelected$;
  }

  ngOnInit(): void {}

  ngAfterViewInit() {}
}
